import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { OutlinedTextFieldProps, TextField, Typography } from "@mui/material";

import { useDebounce } from "../../hooks/useDebounce";

interface TextFieldCustomProps extends Omit<OutlinedTextFieldProps, "variant"> {
  label?: string;
  hasError?: boolean;
  messageError?: string;
  endComponent?: React.ReactNode;
  isDisabled?: boolean;
}

const TextFieldCustom: React.FC<TextFieldCustomProps> = ({
  label,
  hasError,
  messageError,
  endComponent,
  value = "",
  isDisabled = false,
  rows = 0,
  onChange,
  ...props
}) => {
  const { sx: sxProps } = props;

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [text, setText] = useState(value || "");

  const debouncedText = useDebounce(text, 500);
  const debouncedValue = useDebounce(value, 500);

  // NOTE: Verifica aplicacao do ajuste dinamico da altura
  useEffect(() => {
    if (!textAreaRef.current) return;

    const element = textAreaRef.current;
    const lineHeight =
      parseInt(window.getComputedStyle(element).lineHeight, 10) || 24;
    const minHeight =
      typeof rows === "number" && !isNaN(rows) ? rows * lineHeight : lineHeight;

    requestAnimationFrame(() => {
      element.style.height = "auto";
      element.style.minHeight = `${minHeight}px`;
      const adjustedHeight =
        rows === 0 ? element.scrollHeight - 16 : element.scrollHeight;
      element.style.height = `${Math.max(adjustedHeight, minHeight)}px`;
    });
  }, [text]);

  // NOTE: Aplica debounce atualizando o estado do componente pai apos delay
  useEffect(() => {
    if (debouncedText !== value) {
      const valueNew =
        value === "" && debouncedValue !== "" ? "" : debouncedText;

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      onChange?.({
        target: {
          value: valueNew,
        },
      } as ChangeEvent<HTMLInputElement>);

      handleOnChange(valueNew as string);
    }
  }, [debouncedText, value]);

  // Note: Sempre que a URL mudar, limpa o input (apenas para campos editaveis)
  useEffect(() => {
    if (isDisabled) return;
    setText("");
  }, [location.pathname]);

  const handleOnChange = (value: string) => {
    setText(value);
  };

  return (
    <>
      {label && (
        <Typography
          fontSize={14}
          fontWeight={700}
          color={"shadesOfDark.dark"}
          mb={0.5}
          fontFamily={"Open Sans"}
          noWrap
        >
          {label}
        </Typography>
      )}
      <TextField
        disabled={isDisabled}
        fullWidth
        variant="outlined"
        size="small"
        autoComplete="off"
        value={text}
        rows={isDisabled ? undefined : rows}
        onChange={(e) => handleOnChange(e.target.value)}
        sx={{
          ...sxProps,
          width: "100%",
          bgcolor: "background.paper",
          p: 0,
          fontSize: 14,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          "& .MuiOutlinedInput-root.Mui-disabled": {
            backgroundColor: "shadesOfDark.ultraLight",
            "& > input": {
              WebkitTextFillColor: "shadesOfDark.steel",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "shadesOfDark.light",
            },
          },
          "& .MuiOutlinedInput-root": {
            background: "#FFF",
            borderRadius: 1,
            color: "shadesOfDark.dark",
            "& fieldset": {
              borderColor: hasError
                ? "systemColors.error.main"
                : "shadesOfDark.light",
            },
            "& > input": {
              padding: 1.09,
            },
            "&.Mui-focused fieldset": {
              borderColor: hasError
                ? "systemColors.error.main"
                : "shadesOfDark.steel",
            },
            "& textarea": {
              resize: "none",
            },
            "&.MuiInputBase-inputMultiline": {},
          },
        }}
        InputProps={{
          inputRef: textAreaRef,
          endAdornment: <>{endComponent}</>,
        }}
        // removi temporariamente para o input aceitar Nun negativos, voltará em breve!
        // onKeyPress={(event) => {
        //   if (event?.key === "-" || event?.key === "+") {
        //     event.preventDefault();
        //   }
        // }}
        {...props}
      />
      {hasError && (
        <Typography
          mt={0.5}
          fontFamily={"Open Sans"}
          fontSize={10}
          color={"systemColors.error.main"}
        >
          {messageError}
        </Typography>
      )}
    </>
  );
};

export default TextFieldCustom;
