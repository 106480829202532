/* eslint-disable @typescript-eslint/return-await */
import * as _ from "lodash";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { FormikProps } from "formik/dist/types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Yup from "yup";
import CustomButton, {
  CustomButtonVariant,
} from "../../components/CustomButton/CustomButton";
import CustomCard from "../../components/CustomCard/CustomCard";
import Layout from "../../components/Layout/Layout";
import QueryPageTitleCard from "../../components/QueryPageTitleCard/QueryPageTitleCard";
import Select from "../../components/Select/Select";
import TextFieldCustom from "../../components/TextFieldCustom/TextFieldCustom";
import CardTitleWithSubtitle from "../../components/CardTitleWithSubtitle/CardTitleWithSubtitle";
import {
  ISODateToFormat,
  getFormattedTimestamp,
} from "../../utils/dateFormatter";
import { useLocation, useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import BreadcrumbCustom, {
  BreadcrumbChild,
} from "../../components/BreadcrumbCustom/BreadcrumbCustom";
import { StyledIcon } from "../../components/StyledIcon/StyledIcon";
import { getFormatCNPJ } from "../../utils";
import { Types } from "../../ioc/types";
import { useIocContext } from "../../contexts/ioc/IocContext";
import { useDistributor } from "../../contexts/distributor/DistributorContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import FileItem from "../../components/FileItem/FileItem";
import { useSac } from "../../contexts/sac/sacContext";
import {
  SacRegisterStatus,
  SacRegisterStatusIcons,
  SacRegisterTabs,
  commonFilterSac,
} from "../SacPage/models/SearchFilterOptions";
import useDialogAlert from "../../hooks/DialogAlert";
import { IFileUploadDTO } from "../../modules/financial/models/IFileUpload";
import { IInformationService } from "../../modules/information/models/IInformationService";
import { format } from "date-fns";
import { FollowUp } from "../../modules/information/models/GetSacRegisterDTO";
import { IPropsDownloadFile } from "../../modules/information/models/SacRegisterQuery";
import SelectSubsidiaries from "../../components/SelectSubsidiary/SelectSubsidiary";

interface FormProps {
  customerCompanyId: string;
  department: string;
  reasonDescription: string;
  type: string;
  subject: string;
  subsidiaryId: string;
}

const SacRequestPage: React.FC = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const {
    departments,
    subjects,
    createNewSacResgister,
    getAllSubjects,
    followUpSacResgister,
    finishSacResgister,
  } = useSac();
  const { snackbar } = useDialogAlert();
  const {
    dataItemInView,
    setDataItemInView,
    handleDownloadFiles,
    setItemInView,
  } = useSac();
  const navigate = useNavigate();
  const { serviceContainer } = useIocContext();
  const { distributors, subsidiaries } = useDistributor();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [files, setFiles] = React.useState<IFileUploadDTO[] | null>(null);
  const formRef = useRef<FormikProps<FormProps>>(null);
  const informationService = serviceContainer.get<IInformationService>(
    Types.Information.IInformationService
  );
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (dataItemInView) {
      setSelectedDepartment(dataItemInView.department);
    }
  }, [dataItemInView]);

  const [isReply, setIsReply] = useState(false);
  const [reply, setReply] = useState<string>("");

  const isDetails = useMemo(() => {
    return pathname === routesConfig.INFORMATION().SAC_DETAILS;
  }, [pathname]);

  const textFieldCustom = {
    backgroundColor: theme.palette.shadesOfDark.white,
    "& .MuiInputBase-input": {
      WebkitTextFillColor: theme.palette.shadesOfDark.dark,
    },
  };

  const breadcrumbs: BreadcrumbChild[] = [
    {
      isIcon: true,
      link: routesConfig.HOME,
      icon: <StyledIcon iconType={"home"} />,
    },
    {
      link: routesConfig.INFORMATION().SAC,
      label: "REGISTROS DE ATENDIMENTO",
    },
    {
      label: isDetails ? "DETALHE DO ATENDIMENTO" : "NOVO ATENDIMENTO",
    },
  ];

  const handleUploadFile = useCallback(
    async (file: File[]) => {
      if (_.isNull(file)) return;
      const formData = new FormData();
      file.forEach((file, i) => {
        formData.append(`files`, file, file.name);
      });

      try {
        setLoadingUpload(true);
        const response = await informationService.uploadSacFiles(formData);
        setFiles((old) => {
          return old ? [...old, ...response] : response;
        });
      } catch (error) {
        setFiles(null);
        snackbar({
          message: "Erro ao realizar upload, tente novamente.",
          variant: "error",
        });
      } finally {
        setLoadingUpload(false);
      }
    },
    [setFiles]
  );

  const filesInView = useMemo(() => {
    return dataItemInView?.followUps[0]?.attachments || null;
  }, [dataItemInView]);

  const followUps = useMemo(() => {
    const isCostumerFirst =
      dataItemInView?.followUps.length !== 0
        ? dataItemInView?.followUps[0].type === "CUSTOMER"
        : false;
    return isCostumerFirst
      ? dataItemInView?.followUps.slice(1)
      : dataItemInView?.followUps;
  }, [dataItemInView]);

  const isEnableToReply = useMemo(() => {
    return dataItemInView?.status === SacRegisterStatus.WAITING_CONFIRMATION;
  }, [dataItemInView]);

  useEffect(() => {
    if (selectedDepartment) {
      getAllSubjects && getAllSubjects(selectedDepartment);
    }
  }, [selectedDepartment]);

  const handeFileDelete = useCallback(
    (e: IFileUploadDTO) => {
      setFiles((old) => {
        return old && old.filter((file) => file.fileKey !== e.fileKey);
      });
    },
    [setFiles]
  );

  const handeFileDownload = useCallback(
    (e?: any) => {
      if (dataItemInView) {
        const item: IPropsDownloadFile = {
          customerServiceId: dataItemInView.id,
          followUpId: dataItemInView.followUps[0].id,
          attachmentId: e.fileKey,
          fileName: e.fileName,
          fileType: e.fileType,
        };
        handleDownloadFiles && handleDownloadFiles(item);
      }
    },
    [dataItemInView]
  );

  const handleFollowUp = useCallback(async () => {
    if (dataItemInView) {
      setLoading(true);
      const response =
        followUpSacResgister &&
        (await followUpSacResgister(dataItemInView.id, reply));
      setLoading(false);
      if (response) {
        navigate(routesConfig.INFORMATION().SAC, {
          state: {
            tab: 1,
          },
        });
      }
    }
  }, [dataItemInView, reply]);

  const handleFinish = useCallback(async () => {
    try {
      setLoading(true);
      if (dataItemInView) {
        const response =
          finishSacResgister && (await finishSacResgister(dataItemInView.id));
        if (response) {
          navigate(routesConfig.INFORMATION().SAC, {
            state: {
              tab: 2,
            },
          });
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [dataItemInView]);

  const detailsButton = useMemo(() => {
    switch (dataItemInView?.status) {
      case SacRegisterStatus.WAITING_CONFIRMATION:
        return {
          variant: isLoading
            ? CustomButtonVariant.CONTAINED_LOADING
            : CustomButtonVariant.CONTAINED,
          label: isReply ? "Enviar" : "Finalizar atendimento",
          callback: async () =>
            isReply ? await handleFollowUp() : await handleFinish(),
          style: { background: isReply ? "" : "green" },
        };
      case SacRegisterStatus.WAITING_VENDOR:
        return {
          variant: isLoading
            ? CustomButtonVariant.CONTAINED_LOADING
            : CustomButtonVariant.CONTAINED,
          label: "Finalizar atendimento",
          callback: async () =>
            isReply ? await handleFollowUp() : await handleFinish(),
          style: { background: isReply ? "" : "green" },
        };

      default:
        return {
          variant: CustomButtonVariant.CONTAINED,
          label: "Fechar",
          callback: () =>
            navigate(routesConfig.INFORMATION().SAC, {
              state: {
                tab: SacRegisterTabs[
                  dataItemInView?.status as keyof typeof SacRegisterTabs
                ],
              },
            }),
          style: {},
        };
    }
  }, [dataItemInView, isReply, isLoading, handleFollowUp, handleFinish]);

  useEffect(() => {
    return () => {
      setDataItemInView(null);
      setItemInView(null);
    };
  }, []);

  return (
    <Layout
      enableMargin
      appBarProps={{
        notification: {
          onChange() {},
        },
        chat: {
          onChange() {},
        },
        settings: {
          onChange() {},
        },
        avatar: () => (
          <Avatar
            alt="Avatar"
            src="/images/avatar.png"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        ),
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <QueryPageTitleCard title="SAC" />
        </Grid>
        <Grid item xs={12}>
          <CustomCard
            sx={{
              boxShadow: "none",
              border: "1px solid #DFE0EB",
              height: "100%",
              padding: 2.4,
            }}
          >
            <CardTitleWithSubtitle
              title={isDetails ? "Detalhe do atendimento" : "Novo atendimento"}
              icon={
                dataItemInView?.status !== undefined
                  ? SacRegisterStatusIcons[
                      dataItemInView?.status as keyof typeof SacRegisterStatusIcons
                    ]
                  : undefined
              }
              subtitle={getFormattedTimestamp(new Date())}
              buttonProps={{
                title: "Voltar para registros de atendimento",
                onClick: () => {
                  navigate(routesConfig.INFORMATION().SAC);
                },
              }}
            />
            <BreadcrumbCustom data={breadcrumbs} />
            <Divider sx={{ marginTop: 2 }} />
            <Formik
              innerRef={formRef}
              initialValues={{
                customerCompanyId: dataItemInView?.customerCompanyId ?? "",
                department: dataItemInView?.department ?? "",
                reasonDescription: dataItemInView?.reasonDescription ?? "",
                type: dataItemInView?.type ?? "",
                subject: dataItemInView?.subject ?? "",
                subsidiaryId: dataItemInView?.subsidiaryId ?? "",
              }}
              isInitialValid={false}
              validationSchema={Yup.object({
                customerCompanyId: Yup.string().required(
                  "Selecione uma distribuidora"
                ),
                department: Yup.string().required("Selecione um departamento"),
                reasonDescription: Yup.string().required(
                  "Insira o motivo do registro"
                ),
                type: Yup.string().required("Selecione um tipo de registro"),
                subject: Yup.string().required("Selecione um assunto"),
                subsidiaryId: Yup.string().required("Selecione um assunto"),
              })}
              onSubmit={async function (values: FormProps) {
                try {
                  setLoading(true);
                  const newRegister = {
                    ...values,
                    attachments: files,
                  };

                  const response =
                    createNewSacResgister &&
                    (await createNewSacResgister(newRegister));

                  if (response)
                    navigate(routesConfig.INFORMATION().SAC, {
                      state: { tab: 0 },
                    });

                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                }
              }}
            >
              {({
                errors,
                setFieldValue,
                setFieldTouched,
                values,
                touched,
                isValid,
              }) => {
                const [isTyping, setIsTyping] = useState(false);
                const hasErrorDistributor =
                  touched.customerCompanyId &&
                  Boolean(errors.customerCompanyId);
                const hasErrorDepartment =
                  touched.department && Boolean(errors.department);
                const hasErrorType = touched.type && Boolean(errors.type);
                const hasErrorReasonDescription =
                  touched.reasonDescription &&
                  Boolean(errors.reasonDescription);
                const hasErrorSubject =
                  touched.subject && Boolean(errors.subject);
                const hasErrorSubsidiaryId =
                  touched.subsidiaryId && Boolean(errors.subsidiaryId);

                const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

                const handleReasonDescriptionChange = (
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                  setFieldValue: (field: string, value: any) => void,
                  setIsTyping: (value: boolean) => void,
                  textAreaRef: React.RefObject<HTMLTextAreaElement>
                ) => {
                  const value = event.target.value;

                  setFieldValue("reasonDescription", value);

                  if (textAreaRef.current) {
                    textAreaRef.current.style.height = "auto";
                    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
                  }

                  setIsTyping(true);
                };

                useEffect(() => {
                  if (textAreaRef.current && values.reasonDescription) {
                    textAreaRef.current.style.height = "auto";
                    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
                  }
                }, [values.reasonDescription]);

                return (
                  <Form>
                    <Grid container spacing={3} mt={1}>
                      <Grid item xs={12}>
                        <Typography
                          fontFamily={"Open Sans"}
                          fontSize={16}
                          fontWeight={700}
                          color={"shadesOfDark.dark"}
                          display="flex"
                          alignItems="center"
                        >
                          Informações do atendimento
                          {isDetails && dataItemInView && (
                            <StyledIcon
                              ml={1}
                              iconType={
                                SacRegisterStatusIcons[
                                  dataItemInView.status as keyof typeof SacRegisterStatusIcons
                                ]
                              }
                            />
                          )}
                        </Typography>
                        <Divider sx={{ marginTop: 1 }} />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Select
                          label="Razão social"
                          options={distributors.map((option) => ({
                            option,
                          }))}
                          value={distributors.find(
                            (item) => item.id === values.customerCompanyId
                          )}
                          getOptionItemLabel={(value) =>
                            getFormatCNPJ(value) ?? ""
                          }
                          isDisabled={isDetails}
                          getSelectedOptionLabel={(value) =>
                            getFormatCNPJ(
                              value,
                              value.id === values.customerCompanyId ?? false,
                              true,
                              18
                            )
                          }
                          onBlur={() => {
                            setFieldTouched("customerCompanyId", true);
                          }}
                          onChange={async (value) =>
                            setFieldValue("customerCompanyId", value.id)
                          }
                          hasError={hasErrorDistributor}
                          messageError={errors.customerCompanyId}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <SelectSubsidiaries
                          label={"Filial"}
                          valueSubsidiary={subsidiaries.find(
                            (item) => item.id === values.subsidiaryId
                          )}
                          onChangeValue={(selectedOption) => {
                            if (selectedOption) {
                              setFieldValue("subsidiaryId", selectedOption.id);
                            }
                          }}
                          isDisabledValue={isDetails}
                          hasErrorValue={hasErrorSubsidiaryId}
                          messageErrorValue={errors.subsidiaryId}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}></Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Select
                          label="Tipo de registro"
                          options={commonFilterSac.map((option) => ({
                            option,
                          }))}
                          value={commonFilterSac.find(
                            (item) => item.value === values.type
                          )}
                          isDisabled={isDetails}
                          getOptionItemLabel={(value) => value.label ?? ""}
                          getSelectedOptionLabel={(value) => value.label || ""}
                          onBlur={() => {
                            setFieldTouched("type", true);
                          }}
                          onChange={async (value) =>
                            setFieldValue("type", value.value)
                          }
                          hasError={hasErrorType}
                          messageError={errors.type}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Select
                          label="Setor destinatário"
                          options={departments.map((option) => ({
                            option,
                          }))}
                          value={departments.find(
                            (item) => item.code === values.department
                          )}
                          getOptionItemLabel={(value) =>
                            value.description || ""
                          }
                          getSelectedOptionLabel={(value) =>
                            value.description || ""
                          }
                          onBlur={() => {
                            setFieldTouched("department", true);
                          }}
                          isDisabled={isDetails}
                          onChange={(value) => {
                            setFieldValue("department", value.code);
                            if (!_.isUndefined(value.code)) {
                              setSelectedDepartment(value.code);
                            }
                          }}
                          hasError={hasErrorDepartment}
                          messageError={errors.department}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Select
                          label="Assunto"
                          options={subjects.map((option) => ({
                            option,
                          }))}
                          value={subjects.find(
                            (item) => item.code === values.subject
                          )}
                          isDisabled={!values.department || isDetails}
                          getOptionItemLabel={(value) =>
                            value.description || ""
                          }
                          getSelectedOptionLabel={(value) =>
                            value.description || ""
                          }
                          onBlur={() => {
                            setFieldTouched("subject", true);
                          }}
                          onChange={async (value) =>
                            setFieldValue("subject", value.code)
                          }
                          hasError={hasErrorSubject}
                          messageError={errors.subject}
                        />
                      </Grid>

                      <Grid item xs={12} mt={1}>
                        <TextFieldCustom
                          id={"recordReason"}
                          inputRef={textAreaRef}
                          sx={textFieldCustom}
                          fullWidth
                          label="Motivo do registro"
                          multiline
                          rows={
                            values.reasonDescription && !isTyping
                              ? undefined
                              : 4
                          }
                          placeholder="Informe o motivo do registro"
                          value={values.reasonDescription}
                          onChange={(event) =>
                            handleReasonDescriptionChange(
                              event,
                              setFieldValue,
                              setIsTyping,
                              textAreaRef
                            )
                          }
                          isDisabled={isDetails}
                          onBlur={() => {
                            setIsTyping(false);
                            setFieldTouched("reasonDescription", true);
                          }}
                          hasError={hasErrorReasonDescription}
                          messageError={errors.reasonDescription}
                        />
                      </Grid>

                      <DndProvider backend={HTML5Backend}>
                        <Grid item xs={12} md={12}>
                          {!isDetails && (
                            <DragAndDrop
                              onDrop={(item) => {
                                handleUploadFile(item.files);
                              }}
                            />
                          )}
                          {isLoadingUpload && (
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              justifyContent="center"
                              mt={2}
                            >
                              <CircularProgress size={20} color="inherit" />
                            </Grid>
                          )}
                          {files &&
                            files.map((item: IFileUploadDTO) => (
                              <FileItem
                                onDelete={handeFileDelete}
                                data={item}
                                key={item.fileKey}
                              />
                            ))}

                          {filesInView &&
                            filesInView.map((file: IFileUploadDTO) => (
                              <FileItem
                                onDownload={handeFileDownload}
                                data={file}
                                key={file.fileKey}
                              />
                            ))}

                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                          >
                            {followUps &&
                              followUps.map((ele: FollowUp) => (
                                <Grid key={ele.id} item xs={12} md={12} mt={1}>
                                  <Typography
                                    style={{ fontWeight: "bold" }}
                                    color={
                                      ele.type === "VENDOR"
                                        ? theme.palette.systemColors.caution
                                            .dark
                                        : theme.palette.systemColors.success
                                            .main
                                    }
                                  >
                                    {ele.type === "VENDOR"
                                      ? "Resposta da REAM"
                                      : "Cliente"}
                                  </Typography>
                                  <TextFieldCustom
                                    sx={textFieldCustom}
                                    fullWidth
                                    label={`Resposta em ${ISODateToFormat(
                                      ele.createdAt ?? "-"
                                    )} às ${format(
                                      new Date(ele.createdAt || ""),
                                      "HH:mm"
                                    )}`}
                                    multiline
                                    value={ele.message}
                                    isDisabled={isDetails}
                                  />
                                </Grid>
                              ))}
                          </Grid>

                          <Grid item xs={12} mt={1} display="flex">
                            {!isReply && isEnableToReply && (
                              <Typography
                                sx={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  textAlign: "start",
                                }}
                                onClick={() => setIsReply(true)}
                              >
                                {dataItemInView &&
                                dataItemInView?.followUps.length >= 3
                                  ? "Adicionar Resposta +"
                                  : "Adicionar Réplica +"}
                              </Typography>
                            )}
                            {isReply && (
                              <Grid item xs={12} md={12}>
                                <TextFieldCustom
                                  sx={textFieldCustom}
                                  label={
                                    dataItemInView &&
                                    dataItemInView?.followUps.length >= 3
                                      ? "Resposta"
                                      : "Réplica"
                                  }
                                  value={reply || ""}
                                  onChange={(value) => {
                                    setReply(value.target.value);
                                  }}
                                  rows={4}
                                  multiline
                                  fullWidth
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </DndProvider>

                      <Grid item xs={12} mt={10}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-end"}
                      >
                        <Box display={"flex"}>
                          <CustomButton
                            title={"Cancelar"}
                            variant={CustomButtonVariant.OUTLINED}
                            onClick={() =>
                              navigate(routesConfig.INFORMATION().SAC, {
                                state: {
                                  tab: SacRegisterTabs[
                                    dataItemInView?.status as keyof typeof SacRegisterTabs
                                  ],
                                },
                              })
                            }
                          />
                          {isDetails ? (
                            <CustomButton
                              title={detailsButton?.label}
                              style={detailsButton.style}
                              variant={detailsButton?.variant}
                              onClick={async () =>
                                await detailsButton.callback()
                              }
                              disabled={
                                isReply &&
                                !reply &&
                                dataItemInView?.status ===
                                  SacRegisterStatus.WAITING_CONFIRMATION
                              }
                              sx={{
                                ml: 1,
                              }}
                            />
                          ) : (
                            <CustomButton
                              type="submit"
                              title={"Enviar"}
                              disabled={!isValid}
                              variant={
                                isLoading
                                  ? CustomButtonVariant.CONTAINED_LOADING
                                  : isValid
                                  ? CustomButtonVariant.CONTAINED
                                  : CustomButtonVariant.DISABLED
                              }
                              onClick={() => null}
                              sx={{
                                ml: 1,
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </CustomCard>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default SacRequestPage;
